import React from 'react';

import ContentBlock from './ContentBlock';
import RichText from '../atoms/RichText';

export default ({ data }) => (
  <ContentBlock>
    <RichText htmlContent={data} />
  </ContentBlock>
);
