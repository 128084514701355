import React from 'react';
import { graphql } from 'gatsby';

import Heading from '../atoms/Heading';

import HeadOverride from '../components/HeadOverride';
import ContentWrapper from '../components/ContentWrapper';
import SidebarLayout from '../components/SidebarLayout';
import Sidebar from '../components/Sidebar';
import SidebarInfo from '../components/SidebarInfo';
import BreadcrumbsBar from '../components/BreadcrumbsBar';
import ContentBlockText from '../components/ContentBlockText';
import ContentBlockTable2Columns from '../components/ContentBlockTable2Columns';
import ContentBlockStockists from '../components/ContentBlockStockists';

export default props => {
  const { data } = props;
  const breadcrumbsData = data.pageContent.edges[0].node.fields.slugs;
  const pageData = data.pageContent.edges[0].node.data;
  const contentBlocks = pageData.body;

  return (
    <>
      <HeadOverride
        title={pageData.meta_title || pageData.title.text}
        robots={pageData.meta_index}
        description={pageData.meta_description}
        image={pageData.meta_image ? pageData.meta_image.url : undefined}
      />

      <BreadcrumbsBar breadcrumbs={breadcrumbsData} />

      <SidebarLayout withBorders>
        <Sidebar>
          {pageData.sidebar_content && (
            <SidebarInfo
              data={[
                {
                  label: pageData.sidebar_label,
                  content: pageData.sidebar_content,
                },
              ]}
            />
          )}
        </Sidebar>

        <ContentWrapper withSidebar>
          <Heading headingSize={1} visuallyHidden>
            {pageData.title.text}
          </Heading>

          {contentBlocks.map(block => {
            let ContentBlock;

            switch (
              block.__typename // eslint-disable-line no-underscore-dangle
            ) {
              case 'PrismicPageBodyText':
                ContentBlock = (
                  <ContentBlockText
                    key={block.id}
                    data={block.primary.text.html}
                  />
                );
                break;
              case 'PrismicPageBodyTable2Columns':
                ContentBlock = (
                  <ContentBlockTable2Columns
                    key={block.id}
                    title={block.primary.title_table_2_columns}
                    data={block.items}
                  />
                );
                break;
              case 'PrismicPageBodyStockists':
                ContentBlock = (
                  <ContentBlockStockists key={block.id} data={block.items} />
                );
                break;
              default:
                ContentBlock = null;
            }

            return ContentBlock;
          })}
        </ContentWrapper>
      </SidebarLayout>
    </>
  );
};

export const query = graphql`
  query PageQuery($url_path: String!) {
    pageContent: allPrismicPage(
      filter: { fields: { url_path: { eq: $url_path } } }
    ) {
      edges {
        node {
          fields {
            slugs {
              breadcrumb
              slug
            }
          }

          data {
            title {
              text
            }
            meta_title
            meta_description
            meta_index
            meta_image {
              url
            }

            sidebar_label
            sidebar_content

            body {
              __typename
              ... on PrismicPageBodyStockists {
                id

                items {
                  name
                  country
                  city
                  website {
                    url
                  }
                }
              }
              ... on PrismicPageBodyText {
                id
                primary {
                  text {
                    html
                  }
                }
              }
              ... on PrismicPageBodyTable2Columns {
                id
                primary {
                  title_table_2_columns
                }

                items {
                  text_column_1 {
                    html
                  }

                  text_column_2 {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
