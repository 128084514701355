import React from 'react';

import ContentBlock from './ContentBlock';
import Table from './Table';


export default ({ data, title }) => {
  const contentData = data.map(row => ([
    row.text_column_1.html,
    row.text_column_2.html,
  ]));

  return (
    <ContentBlock title={title}>
      <Table columns={2} content={contentData} />
    </ContentBlock>
  );
};
