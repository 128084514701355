import React from 'react';

import ContentBlock from './ContentBlock';
import Table from './Table';

export default ({ data }) => {
  const headerData = ['Name', 'Country', 'City'];
  const contentData = data.map(row => [
    `<strong>${
      row.website
        ? `<a href="${row.website.url}" target="_blank">${row.name}</a>`
        : row.name
    }</strong>`,
    row.country,
    row.city,
  ]);

  return (
    <ContentBlock>
      <Table columns={3} header={headerData} content={contentData} />
    </ContentBlock>
  );
};
