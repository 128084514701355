import React from 'react';
import styled from 'styled-components';

import scale from '../utils/scale';

import Heading from '../atoms/Heading';

const ContentBlock = styled.div`
  padding: ${scale(1.25)} 0;

  &:first-child {
    padding-top: ${scale(1)};
  }
`;

const ContentBlockHeading = styled(Heading)`
  && {
    padding-bottom: ${scale(0.5)};
  }
`;

export default ({ children, title }) => (
  <ContentBlock>
    {title && (
      <ContentBlockHeading headingSize={3} use="h2">
        {title}
      </ContentBlockHeading>
    )}

    {children}
  </ContentBlock>
);
